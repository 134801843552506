import { Injectable, Type } from '@angular/core';
import findKey from 'lodash-es/findKey';

import { LinksService } from '@app/core/links.service';
import { HomepageNode } from '@app/registration/graph/nodes/homepage.node';
import { MfaNode } from '@app/registration/graph/nodes/mfa.node';
import { PrepaidConfirmationNode } from '@app/registration/graph/nodes/prepaid-confirmation.node';
import { RegistrationNode, RegistrationNodeError } from '@app/registration/graph/nodes/registration.node';
import { ServiceAreaNode } from '@app/registration/graph/nodes/service-area.node';
import { TermsOfServiceNode } from '@app/registration/graph/nodes/terms-of-service.node';

import { TrialVideoVisitConfirmationNode } from './nodes/trial-video-visit-confirmation.node';

@Injectable({
  providedIn: 'root',
})
export class NodeUrlMappingService {
  constructor(private linksService: LinksService) {}

  getUrlForNode(targetNode: Type<RegistrationNode>): string {
    const nodeUrlMapping = this.getNodeUrlMapping();
    const url = findKey(nodeUrlMapping, node => node === targetNode);
    if (!url) {
      throw new RegistrationNodeError(`Url not found for node: ${targetNode.name}`);
    }
    return url;
  }

  getNodeForUrl(url: string): Type<RegistrationNode> | undefined {
    const nodeUrlMapping = this.getNodeUrlMapping();

    return nodeUrlMapping[url];
  }

  private getNodeUrlMapping() {
    return {
      [this.linksService.registrationServiceArea]: ServiceAreaNode,
      [this.linksService.trialVideoVisitConfirmation]: TrialVideoVisitConfirmationNode,
      [this.linksService.termsOfService]: TermsOfServiceNode,
      [this.linksService.home]: HomepageNode,
      [this.linksService.prepaidConfirmation]: PrepaidConfirmationNode,
      [this.linksService.setupMfa]: MfaNode,
    };
  }
}
