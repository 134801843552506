import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { BillingAndInsurance } from './__generated__/billing-and-insurance-graphql.service.types';

export const insuranceInfo = gql`
  fragment InsuranceInfo on Insurance {
    id
    copay
    name
    subscriberNumber
    isPendingReview
    insuranceType {
      id
      isSelfPay
    }
    planType
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BillingAndInsuranceGraphQLService extends Query<BillingAndInsurance> {
  document = gql`
    query BillingAndInsurance {
      patient {
        id
        primaryInsurance {
          ...InsuranceInfo
        }
        activeInsurances {
          ...InsuranceInfo
        }
        profileInfo {
          id
          onlyHasSelfPay
          insuranceVerifiedWithinPastYear
        }
      }
    }
    ${insuranceInfo}
  `;
}
