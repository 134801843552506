import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { AppointmentAnalyticsProperty } from '@app/core/appointment-analytics-base.service';
import { LinksService } from '@app/core/links.service';
import { PaymentMethod } from '@app/membership/settings/__generated__/payment-methods-graphql.service.types';
import { InsuranceCaptureService } from '@app/shared/insurance-capture/insurance-capture.service';
import { PaymentCaptureService } from '@app/shared/payment-capture/payment-capture.service';

export enum PaymentStep {
  CONFIRM_METHOD = 'confirm-method',
  COPAY_REQUIRED = 'copay-required',
  SELECT_METHOD = 'select-method',
  ADD_METHOD = 'add-method',
}

export enum ManagePaymentMethodsQueryParams {
  SOURCE = 'source',
  CONFIRMATION_ID = 'confirmationId',
}

export enum ManagePaymentMethodsSource {
  INSURANCE = 'insurance',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

export const ManagePaymentMethodsSourcePropertyMap: Record<ManagePaymentMethodsSource, string> = {
  [ManagePaymentMethodsSource.INSURANCE]: AppointmentAnalyticsProperty.AddInsurancePagePreBookingModalModule,
  [ManagePaymentMethodsSource.REVIEW]: AppointmentAnalyticsProperty.AppointmentSelectedPage,
  [ManagePaymentMethodsSource.CONFIRMATION]: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
} as const;

@Component({
  selector: 'om-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss'],
})
export class ManagePaymentMethodsComponent implements OnInit {
  PaymentStep = PaymentStep;
  initialStep: PaymentStep;
  currentStep: PaymentStep;
  showSkip: boolean;
  source: ManagePaymentMethodsSource;
  confirmationId: string | null;
  historyStack: PaymentStep[] = [];
  defaultCopayMethod: PaymentMethod | null;
  paymentMethods: PaymentMethod[];
  currentPaymentMethod: PaymentMethod;
  canSetDefault: boolean;
  copay: number;
  isFlagOptional: boolean;
  protected loading = true;

  constructor(
    private readonly paymentCaptureService: PaymentCaptureService,
    private readonly insuranceCaptureService: InsuranceCaptureService,
    private readonly router: Router,
    private readonly links: LinksService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParamMap;
    this.source = params.get(ManagePaymentMethodsQueryParams.SOURCE) as ManagePaymentMethodsSource;
    this.confirmationId = params.get(ManagePaymentMethodsQueryParams.CONFIRMATION_ID);

    combineLatest([
      // Cache insurance data to prevent reactive changes due to delayed verification
      this.insuranceCaptureService.getPrimaryInsurance$().pipe(take(1)),
      this.paymentCaptureService.getPaymentMethods$(),
      this.paymentCaptureService.getPaymentCaptureFlagOptional$(),
    ]).subscribe(([insurance, paymentMethods, isFlagOptional]) => {
      this.copay = insurance.copay || 0;
      this.canSetDefault = this.source === ManagePaymentMethodsSource.INSURANCE && this.copay > 0;
      this.paymentMethods = paymentMethods;
      this.defaultCopayMethod = this.paymentMethods.find(method => method.isDefaultCopay);
      this.currentPaymentMethod = this.defaultCopayMethod || paymentMethods?.[0];
      this.isFlagOptional = isFlagOptional;
      this.loading = false;
      if (!this.currentStep) {
        this.initialStep = this.canSetDefault ? PaymentStep.COPAY_REQUIRED : PaymentStep.ADD_METHOD;
        this.goToStep(this.initialStep);
      }
    });
  }

  goBack() {
    if (this.historyStack.length > 1) {
      // If there's local history, go back within component
      this.historyStack.pop();
      const step = this.historyStack.pop();
      this.goToStep(step);
    } else {
      // Else return to source
      this.goToSource();
    }
  }

  goToStep(step: PaymentStep) {
    this.showSkip =
      this.source === ManagePaymentMethodsSource.INSURANCE && step === this.initialStep && this.isFlagOptional;
    this.historyStack.push(step);
    this.currentStep = step;
  }

  goToDestination() {
    if (this.source === ManagePaymentMethodsSource.INSURANCE) {
      // If part of booking flow, continue to review page
      this.router.navigate([this.links.appointmentReview], { replaceUrl: true });
    } else {
      // Else return to source
      this.goToSource();
    }
  }

  private goToSource() {
    const routeMap = {
      [ManagePaymentMethodsSource.INSURANCE]: this.links.manageInsurance,
      [ManagePaymentMethodsSource.REVIEW]: this.links.appointmentReview,
      [ManagePaymentMethodsSource.CONFIRMATION]: this.confirmationId
        ? this.links.appointmentConfirmation(this.confirmationId)
        : this.links.home,
    };
    this.router.navigate([routeMap[this.source] || this.links.home], { replaceUrl: true });
  }
}
