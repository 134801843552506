import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MfaToHomepageEdge } from '@app/registration/graph/edges/mfa-to-homepage.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';

@Injectable({
  providedIn: 'root',
})
export class MfaNode extends RegistrationNode {
  constructor(mfaToHomepageEdge: MfaToHomepageEdge, nodeUrlMappingService: NodeUrlMappingService, router: Router) {
    super(nodeUrlMappingService, router);
    this.edges = [mfaToHomepageEdge];
  }
}
